import "../Assets/Styles/Feedback.css";
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import LazyLoad from "react-lazy-load";
import { Trans, useTranslation } from "react-i18next";
import { FeedbackList } from "../mock-data/mock-data";
import { motion } from "framer-motion";

import "swiper/swiper-bundle.css";
import { useBreakpoints } from "../utils/useBreakpoints";

const createSlides = (array, i18n) => {
  
  return array.map((item, i) => (
    <SwiperSlide key={`${item?.name}-${i}`}>
      <div className="carousel-center">
        <div className="feedback-carousel-card">
          <div className="text-container">
            <div className="feedback-carousel-person">
              <LazyLoad>
                <img alt="avatar" src={item?.avatar} />
              </LazyLoad>
              <div className="feedback-carousel-desc">
                <p className="feedback-carousel-name">{item?.name}</p>
                <p>{item?.position}</p>
              </div>
            </div>
            <p className="feedback-carousel-message message-short">{i18n.t(item?.comment)}</p>
          </div>
          {item?.link && (
            <a href={item?.link} target="_blank" rel="noopener noreferrer">
              <motion.button
                initial={{ x: 100, opacity: 0, scale: 1 }}
                whileInView={{ x: 0, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="sect-button view-all-btn clutch-button"
              >
                <Trans>View on</Trans>
                <img className="clutch-image" src='https://res.cloudinary.com/dq9w8bxge/image/upload/v1687274472/clutch-co-vector-logo_kluws5.svg' />
              </motion.button>
            </a>
          )}
        </div>
      </div>
    </SwiperSlide>
  ));
};

const FeedbackCarousel = () => {
  const { isMdScreen, isXlScreen } = useBreakpoints();
  const { i18n } = useTranslation();
  const [items, setItems] = useState([]);
  
  const isControlButtonHidden =  !isMdScreen || items.length <= 2 || isXlScreen && items.length === 3;
  
  useEffect(() => {
    setItems(createSlides(FeedbackList, i18n));
  }, [i18n.language]);
  
  return (
    <div className="carousel-wrap">
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{ prevEl: '.feedback-swiper-button-prev', nextEl: '.feedback-swiper-button-next' }}
        pagination={!isMdScreen ? { clickable: true } : false}
        spaceBetween={50}
        slidesPerView={1}
        breakpoints={{
          768: { slidesPerView: 2 },
          1280: { slidesPerView: 3 }
        }}
        loop={true}
      >
        {items}
      </Swiper>
      
      <div className={`feedback-swiper-button-prev swiper-button-prev ${isControlButtonHidden ? 'swiper-button-prev-hidden' : ''}`} />
      <div className={`feedback-swiper-button-next swiper-button-next ${isControlButtonHidden ? 'swiper-button-next-hidden' : ''}`} />
    </div>
  );
};

export const MemoizedFeedbackCarousel = React.memo(FeedbackCarousel);
