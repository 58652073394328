import { Navigate, Route, Routes } from "react-router-dom";
import Main from "../Pages/Main/Main";
import React, { lazy } from "react";
import { paths } from "./index";

const Careers = lazy(() => import('../Pages/Careers/Careers'));
const Career = lazy(() => import('../Pages/Career/Career'));
const Portfolio = lazy(() => import('../Pages/Portfolio/Portfolio'));
const Project = lazy(() => import('../Pages/Project/Project'));
// const Healthcare = lazy(() => import('../Pages/Domains/Healthcare/Healthcare')); //TODO temporary commented Healthcare
const IoT = lazy(() => import('../Pages/Domains/IoT/IoT'));
const Ordering = lazy(() => import('../Pages/Domains/Ordering/Ordering'));
const Tourism = lazy(() => import('../Pages/Domains/Tourism/Tourism'));
const Education = lazy(() => import('../Pages/Domains/Education/Education'));
const Tools = lazy(() => import('../Pages/Domains/Tools/Tools'));
const Android = lazy(() => import('../Pages/Services/Android/Android'));
const Flutter = lazy(() => import('../Pages/Services/Flutter/Flutter'));
const IOS = lazy(() => import('../Pages/Services/IOS/IOS'));
const NodeComponent = lazy(() => import('../Pages/Services/Node/Node'));
const ReactComponent = lazy(() => import('../Pages/Services/React/React'));
const Login = lazy(() => import('../Pages/Admin/Login'));
const Editor = lazy(() => import('../Pages/Admin/Editor'));
const Entertainment = lazy(() => import('../Pages/Domains/Entertainment/Entertainment'));
const AI = lazy(() => import('../Pages/Domains/AI/AI'));
const Article = lazy(() => import('../Pages/Article/Article'));
const Blog = lazy(() => import('../Pages/Blog/Blog'));

//todo: check this comp
const ForStartups = lazy(() => import('../Pages/ForStartups/ForStartups'));

export const RoutesComponent = ({ isLoading, queryLang, userLocale }) => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Navigate to={`/${queryLang || userLocale === 'uk' || userLocale === 'ru' ? 'ua' : 'en'}`} replace/>
        }
      />
      <Route path="/:lang" element={<Main isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.admin}`} element={<Login/>}/>
      <Route path={`/:lang${paths.adminArticles}`} element={<Editor/>}/>
      <Route path={`/:lang${paths.adminArticles}/:id`} element={<Editor/>}/>
      
      <Route path={`/:lang${paths.careersPage}`} element={<Careers/>}/>
      <Route path={`/:lang${paths.careersPage}/:id`} element={<Career/>}/>
      <Route path={`/:lang${paths.portfolioPage}`} element={<Portfolio/>}/>
      <Route path={`/:lang${paths.portfolioPage}/:id`} element={<Project/>}/>
      <Route path={`/:lang${paths.forStartups}`} element={<ForStartups/>}/>
      <Route path={`/:lang${paths.blog}`} element={<Blog/>}/>
      <Route path={`/:lang${paths.IoT}`} element={<IoT isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.blog}/:id`} element={<Article/>}/>
      
      <Route path={`/:lang${paths.domains}/iot`} element={<IoT isLoading={isLoading}/>}/>
      {/*<Route path={`/:lang${paths.domains}/healthcare`} element={<Healthcare isLoading={isLoading}/>}/>*/} {/*TODO temporary commented Healthcare*/}
      <Route path={`/:lang${paths.domains}/tourism`} element={<Tourism isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.domains}/ordering-system`} element={<Ordering isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.domains}/education`} element={<Education isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.domains}/tools-&-utilities`} element={<Tools isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.domains}/entertainment`} element={<Entertainment isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.domains}/ai`} element={<AI isLoading={isLoading}/>}/>
      
      <Route path={`/:lang${paths.services}/android`} element={<Android isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.services}/ios`} element={<IOS isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.services}/react`} element={<ReactComponent isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.services}/node`} element={<NodeComponent isLoading={isLoading}/>}/>
      <Route path={`/:lang${paths.services}/flutter`} element={<Flutter isLoading={isLoading}/>}/>
    </Routes>
  )
}

export default React.memo(RoutesComponent)