export const FeedbackList = [
  {
    name: "Jeremy Reynaud",
    position: "CEO - ShareItAgain",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "I’ve successfully outsourced several projects to Taras and his team, over the past few years. They have a strong understanding of current development best practices and always provide good communication. All these factors keep us hiring them for more work.",
    link: "https://clutch.co/profile/tetalab-0#review-2137576"
  },
  {
    name: "Aabesh De",
    position: "CEO - Florasence",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab has been instrumental to Flora’s app growth over the last year. Taras from TetaLab was a pleasure to work with, and as a result of their design, iOS , and Android development services, we were able to grow our revenue 3x in just 6 months while working with them. Their team was able to integrate with our technical team immediately, and were easy to communicate and work with. I can't recommend them enough!",
    link: "https://clutch.co/profile/tetalab-0#review-2149820",
  },
  {
    name: "Oleh Smolkin",
    position: "CEO - Startup Allez",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "TetaLab team was very helpful with in-time delivery of MVP. They have worked collaboratively and efficiently provide high-quality code in terms of uncertainty.",
    link: "https://clutch.co/profile/tetalab-0#review-2129195"
  },
  {
    name: "Halvard Maalqvist",
    position: "CEO - Ackurat Learn",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab had provided exceptional approach to every stage of the developing process."
  },
];

export const FeedbackList_ua = [
  {
    name: "Jeremy Reynaud",
    position: "CEO - ShareItAgain",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "За останні роки я успішно передав декілька проєктів на аутсорс Тарасу та його команді. Вони добре розуміють сучасні найкращі практики розробки та завжди забезпечують якісну комунікацію. Усі ці фактори спонукають нас наймати цю команду надалі.",
    link: "https://clutch.co/profile/tetalab-0#review-2137576"
  },
  {
    name: "Aabesh De",
    position: "CEO - Florasence",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab відіграли важливу роль у розвитку додатка Flora протягом минулого року. З Тарасом із TetaLab було приємно працювати, і завдяки їхнім послугам розробки дизайну, розробки на iOS та Android ми змогли збільшити свій дохід у 3 рази всього лише за 6 місяців роботи з ними. Команда вміло взаємодіяла з нашою технічною командою, з ними було легко спілкуватися та працювати. Рекомендую їх для співпраці!",
    link: "https://clutch.co/profile/tetalab-0#review-2149820",
  },
  {
    name: "Oleh Smolkin",
    position: "CEO - Startup Allez",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "Команда TetaLab дуже допомогла розробити MVP у визначені терміни. Вони ефективно працювали та створювали високоякісний код в умовах невизначеності.",
    link: "https://clutch.co/profile/tetalab-0#review-2129195"
  },
  {
    name: "Halvard Maalqvist",
    position: "CEO - Ackurat Learn",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab забезпечили винятковий підхід до кожного етапу процесу розробки."
  },
];

export const CareersList = [
  {
    id: "lead_generation_specialist",
    title: "Lead Generation Specialist",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/Junior_Sales_Manager_onhsiy.svg",
    requiredSkills: [
      "Fluent/Advanced English",
      "Strong communications skills",
      "Perseverance, attentiveness to details, regularity",
      "Self-motivated and focused on growth and success within a company"
    ],
    plus: [
      "Knowledge and understanding of the market and the specifics of sales in IT",
      "Experience with lead generation and international sales of software services (UpWork, Linkedin)"
    ],
    offer: [
      "Well paid Full-time job",
      "Career growth in the company",
      "We teach how to work and provide all the required info"
    ],
    responsibilities: [
      "Search & prepare proposals for potential customers on Upwork and LinkedIn",
      "Communicate with potential customers to promote products and company services",
      "Improve and optimize the process of communication with clients"
    ]
  }
]

export const CareersList_ua = [
  {
    id: "lead_generation_specialist",
    title: "Lead Generation Specialist",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/Junior_Sales_Manager_onhsiy.svg",
    requiredSkills: [
      "Вільне володіння англійською мовою",
      "Сильні комунікативні навички",
      "Посидючість, уважність до деталей, регулярність",
      "Само мотивованість і зосередженість на зростанні та успіху в компанії"
    ],
    plus: [
      "Знання та розуміння ринку та специфіки продажів в IT",
      "Досвід генерації потенційних клієнтів та міжнародних продажів програмного забезпечення (UpWork, Linkedin)"
    ],
    offer: [
      "Добре оплачувана повна зайнятість",
      "Кар'єрний ріст в компанії",
      "Навчаємо працювати та надаємо всю необхідну інформацію"
    ],
    responsibilities: [
      "Пошук і підготовка пропозицій для потенційних клієнтів на Upwork і LinkedIn",
      "Спілкування з потенційними клієнтами для просування продуктів і послуг компанії",
      "Покращити та оптимізувати процес спілкування з клієнтами"
    ]
  },
];

export const Articles = [
  {
    id: "why_use_iot_technology",
    title: "Why are entrepreneurs using IoT technology, and how can Tetalab help?",
    img: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_1_zqymmu.png",
    video: "",
    readTime: "5",
    date: "June 8, 2023",
    article: [
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_pjeyou.png"
      },
      {
        type: "h2",
        style: "",
        text: "Did you realize that by 2025 the use of IoT technology is expected to increase by 20% every year, resulting in a market worth $20.2 billion?"
      },
      {
        type: "p",
        style: "",
        text: "The Internet of Things (IoT) is a network of interconnected gadgets that can communicate with one another and other systems over the Internet. This technology is quickly gaining traction among startups because of its advantages. There are some examples of how it can be helpful in today's competitive market:"
      },
      {
        type: "h2",
        style: "",
        text: "Firstly"
      },
      {
        type: "p",
        style: "",
        text: "Real-time data provided by IoT technology can be used by startups to analyze and improve their operations. For instance, businesses can use sensors to maintain inventory levels and track their equipment's efficiency, allowing them to make data-driven decisions that will increase productivity and cut costs. "
      },
      {
        type: "h2",
        style: "",
        text: "Secondly"
      },
      {
        type: "p",
        style: "",
        text: "IoT systems can be combined with other technologies to produce robust solutions to improve consumer satisfaction and open new revenue streams. Examples of these different technologies include cloud computing and artificial intelligence."
      },
      {
        type: "h2",
        style: "",
        text: "Thirdly"
      },
      {
        type: "p",
        style: "",
        text: "IoT technology is a desirable alternative for companies with limited resources because it is relatively simple to adopt and manage at existing facilities."
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_1_zqymmu.png",
      },
      {
        type: "p",
        style: "",
        text: "The Smart GreenHouse created by Tetalab Company is the ideal illustration of the use of IoT technology. The project's goal is to create an automated irrigation system that uses IoT technology to monitor and manage the water supply to plants constantly. The system gathers data on soil moisture, temperature, and humidity using sensors and wirelessly relays to a microcontroller. After processing the data, the microcontroller activates an actuator to adjust the water flow to the plants.",
      },
      {
        type: "p",
        style: "",
        text: "The system, created by our team of experts, can be managed remotely via a mobile application that offers real-time data on soil conditions and allows the user to change the irrigation schedule as necessary."
      },
      {
        type: "quote",
        style: "",
        text: "The greatest value of this app is that it helps to reduce the amount of water used for watering plants, reduces the amount of electricity used, and controls and improves plant growth."
      },
      {
        type: "p",
        style: "",
        text: "In conclusion, IoT technology is quickly gaining popularity among entrepreneurs due to its various advantages that can help them compete successfully in today's market. IoT systems give companies a strong tool for developing cutting-edge solutions since they provide real-time data, integrate with other technologies, and are simple to implement and manage. Agriculture and landscape irrigation companies and even home gardeners are potential uses for Smart Irrigation System (or Name of Application). Overall, the project shows how IoT technology can be used to develop creative answers to pressing issues like sustainable agriculture and water conservation."
      },
      {
        type: "h2",
        style: "",
        text: "Are you interested in IoT and have an idea you want to bring to life? Tetalab experts will help you to implement it and bring your business to the next level"
      },
    ]
  },
  {
    id: "Top5IoTIdeas",
    title: "The Top 5 IoT Ideas That Will Change the Future of Business in 2023",
    img: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_1_xv5tfb.png",
    video: "",
    readTime: "9",
    date: "June 8, 2023",
    article: [
      {
        type: "h2",
        style: "",
        text: "Do you know that the global IoT market size is expected to reach $1.1 trillion by 2026, with a compound annual growth rate (CAGR) of 24.7% from 2019 to 2026?"
      },

      {
        type: "p",
        style: "",
        text: "Businesses are now able to operate more effectively, develop better products, and offer better services with the help of the Internet of Things (IoT). IoT has completely transformed how businesses function in recent years.  There are a number of innovative IoT ideas that will completely change business as we approach 2023. In this essay, we will look at the most cutting-edge ideas:"
      },
      {
        type: "h2",
        style: "",
        text: "1. Autonomous Vehicles"
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230851/big_1_1_ofcyd8.png"
      },
      {
        type: "p",
        style: "",
        text: "The transportation sector could be completely transformed by autonomous vehicles, an IoT application that is quickly expanding. The efficiency of logistics and transportation can be increased by using self-driving automobiles and trucks, which may also reduce traffic jams. In the next 2-3 years, more companies will probably employ autonomous cars to move people and things, especially in sectors like logistics, delivery, and transportation",
      },
      {
        type: "h2",
        style: "",
        text: "2. Smart factories"
      },
      {
        type: "p",
        style: "",
        text: "Smart factories are one of the most crucial IoT ideas that will change the future of business. Sensors, cameras, and other Internet of Things (IoT) devices will be implemented in these factories to enable real-time monitoring of production processes, ensuring that goods are produced successfully and with the smallest amount of waste possible. Smart factories will be able to identify and correct production faults before they become severe problems, resulting in better-quality goods, less downtime, and increased worker safety."
      },
      {
        type: "h2",
        style: "",
        text: "3. Personalized Marketing"
      },
      {
        type: "p",
        style: "",
        text: "IoT can be used for gathering and analyzing client data, allowing businesses to personalize their advertising campaigns. Businesses are able to discover more about consumer habits and preferences by analyzing data from wearables, smart appliances, and other IoT devices. More companies will likely use IoT in 2023 to develop individualized marketing campaigns that address the needs and preferences of specific customers."
      },
      {
        type: "h2",
        style: "",
        text: "4. Smart Cities"
      },
      {
        type: "p",
        style: "",
        text: "IoT can be used to build smart cities, which are intended to enhance the efficiency of urban infrastructure and improve the quality of life for residents. Cities may optimize operations and provide their residents with better services by installing sensors and other IoT devices that track traffic, air quality, and additional significant metrics. We might expect more building companies will launch smart city programs in 2023 that employ IoT to raise the standard of living for residents."
      },
      {
        type: "h2",
        style: "",
        text: "5. Medical devices"
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_1_xv5tfb.png"
      },
      {
        type: "p",
        style: "",
        text: "The field of healthcare devices is another area where IoT expects to have a significant impact. By offering real-time monitoring and analysis of vital signs and other health parameters, IoT-enabled medical devices may improve patient outcomes. These tools can be used to identify potential health problems before they develop into significant ones, enabling medical professionals to act quickly and provide care. We are likely to see more medical devices include IoT technology as it develops, which will give patients and healthcare professionals access to more detailed and accurate health information, ultimately improving patient outcomes."
      },
      {
        type: "h2",
        style: "",
        text: "The field of healthcare devices is another area where IoT expects to have a significant impact. By offering real-time monitoring and analysis of vital signs and other health parameters, IoT-enabled medical devices may improve patient outcomes. These tools can be used to identify potential health problems before they develop into significant ones, enabling medical professionals to act quickly and provide care. We are likely to see more medical devices include IoT technology as it develops, which will give patients and healthcare professionals access to more detailed and accurate health information, ultimately improving patient outcomes.",
      },

    ]
  },
];

export const Articles_ua = [
  {
    id: "why_use_iot_technology",
    title: "Чому підприємці використовують технологію IoT і як Tetalab може допомогти?",
    img: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_1_zqymmu.png",
    video: "",
    reading: "5",
    date: "June 8, 2023",
    article: [
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_1_zqymmu.png",

      },
      {
        type: "h2",
        style: "",
        text: "Did you realize that by 2025 the use of IoT technology is expected to increase by 20% every year, resulting in a market worth $20.2 billion?"
      },
      {
        type: "p",
        style: "",
        text: "Інтернет речей (IoT) — це мережа взаємопов’язаних гаджетів, які можуть спілкуватися один з одним та іншими системами через Інтернет. Завдяки своїм перевагам ця технологія швидко набирає популярності серед стартапів. Ось кілька прикладів того, як це може бути корисним на сучасному конкурентному ринку:"
      },

      {
        type: "h2",
        style: "",
        text: "По-перше"
      },
      {
        type: "p",
        style: "",
        text: "Дані в реальному часі, які надає технологія IoT, можуть використовуватися стартапами для аналізу та покращення своєї діяльності. Наприклад, підприємства можуть використовувати датчики для підтримки рівня запасів і відстеження ефективності свого обладнання, що дозволяє їм приймати рішення на основі даних, які підвищать продуктивність і скоротять витрати."
      },
      {
        type: "h2",
        style: "",
        text: "По-друге"
      },
      {
        type: "p",
        style: "",
        text: "Cистеми Інтернету речей можна поєднувати з іншими технологіями для створення надійних рішень для підвищення задоволеності споживачів і відкриття нових джерел доходу. Приклади цих різних технологій включають хмарні обчислення та штучний інтелект."
      },
      {
        type: "h2",
        style: "",
        text: "По-третє"
      },
      {
        type: "p",
        style: "",
        text: " Tехнологія Інтернету речей є бажаною альтернативою для компаній з обмеженими ресурсами, оскільки її відносно просто прийняти та керувати нею на існуючих об’єктах."
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_pjeyou.png"
      },
      {
        type: "p",
        style: "",
        text: "Розумна теплиця, створена компанією Tetalab, є ідеальною ілюстрацією використання технології IoT. Метою проекту є створення автоматизованої системи поливу, яка використовує технологію IoT для постійного моніторингу та керування водопостачанням рослин. Система збирає дані про вологість ґрунту, температуру та вологість за допомогою датчиків і бездротових реле на мікроконтролер. Після обробки даних мікроконтролер активує актуатор для регулювання потоку води до рослин."
      },
      {
        type: "p",
        style: "",
        text: "Системою, створеною нашою командою експертів, можна керувати дистанційно за допомогою мобільного додатку, який надає дані про стан ґрунту в режимі реального часу та дозволяє користувачеві за потреби змінювати графік поливу."
      },
      {
        type: "quote",
        style: "",
        text: "Найбільша цінність цієї програми полягає в тому, що вона допомагає зменшити кількість води для поливу рослин, зменшує кількість споживаної електроенергії, а також контролює та покращує ріст рослин."
      },
      {
        type: "p",
        style: "",
        text: "Підсумовуючи, технологія IoT швидко набирає популярності серед підприємців завдяки різноманітним перевагам, які можуть допомогти їм успішно конкурувати на сучасному ринку. Системи Інтернету речей дають компаніям потужний інструмент для розробки передових рішень, оскільки вони надають дані в режимі реального часу, інтегруються з іншими технологіями та є простими у впровадженні та керуванні. Сільськогосподарські та ландшафтні іригаційні компанії та навіть домашні садівники потенційно можуть використовувати розумну іригаційну систему (або назву програми). Загалом проект показує, як технологію IoT можна використовувати для розробки творчих відповідей на нагальні проблеми, такі як стале сільське господарство та збереження води."
      },
      {
        type: "h2",
        style: "",
        text: "Ви зацікавлені в IoT і маєте ідею, яку хочете втілити в життя? Фахівці Tetalab допоможуть вам це реалізувати та вивести бізнес на новий рівень"
      },
    ]
  },
  {
    id: "Top5IoTIdeas",
    title: "5 найкращих ідей IoT, які змінять майбутнє бізнесу у 2023 році",
    img: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_1_xv5tfb.png",
    video: "",
    reading: "9",
    date: "June 8, 2023",
    article: [
      {
        type: "h2",
        style: "",
        text: "Чи знаєте ви, що очікується, що до 2026 року розмір світового ринку IoT досягне 1,1 трильйона доларів США, а з 2019 до 2026 року загальний річний темп зростання (CAGR) складе 24,7%?"
      },

      {
        type: "p",
        style: "",
        text: "Тепер підприємства можуть працювати ефективніше, розробляти кращі продукти та пропонувати кращі послуги за допомогою Інтернету речей (IoT). За останні роки Інтернет речей повністю змінив принцип функціонування бізнесу. Існує низка інноваційних ідей IoT, які повністю змінять бізнес, коли ми наближаємося до 2023 року. У цьому есе ми розглянемо найсучасніші ідеї:"
      },
      {
        type: "h2",
        style: "",
        text: "1. Автономні транспортні засоби"
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230851/big_1_1_ofcyd8.png"
      },
      {
        type: "p",
        style: "",
        text: "Транспортний сектор може бути повністю перетворений завдяки автономним транспортним засобам, додатку IoT, який швидко розширюється. Ефективність логістики та транспортування можна підвищити, використовуючи безпілотні автомобілі та вантажівки, що також може зменшити затори. У найближчі 2-3 роки більше компаній, ймовірно, використовуватимуть автономні автомобілі для переміщення людей і речей, особливо в таких секторах, як логістика, доставка та транспорт"
      },
      {
        type: "h2",
        style: "",
        text: "2. Розумні заводи"
      },
      {
        type: "p",
        style: "",
        text: "Розумні фабрики є однією з найважливіших ідей IoT, яка змінить майбутнє бізнесу. Датчики, камери та інші пристрої Інтернету речей (IoT) будуть впроваджені на цих фабриках, щоб забезпечити моніторинг виробничих процесів у реальному часі, гарантуючи, що товари виробляються успішно та з найменшою кількістю відходів. Розумні фабрики зможуть виявляти та виправляти виробничі помилки, перш ніж вони стануть серйозними проблемами, що призведе до кращої якості товарів, меншого часу простою та підвищення безпеки працівників."
      },
      {
        type: "h2",
        style: "",
        text: "3. Персоналізований маркетинг"
      },
      {
        type: "p",
        style: "",
        text: "IoT можна використовувати для збору та аналізу даних клієнтів, що дозволяє компаніям персоналізувати свої рекламні кампанії. Підприємства можуть дізнаватися більше про звички та вподобання споживачів, аналізуючи дані з носіїв, розумних пристроїв та інших пристроїв Інтернету речей. Ймовірно, у 2023 році більше компаній використовуватимуть IoT для розробки індивідуальних маркетингових кампаній, які відповідають потребам і перевагам конкретних клієнтів."
      },
      {
        type: "h2",
        style: "",
        text: "4. Розумні міста"
      },
      {
        type: "p",
        style: "",
        text: "IoT можна використовувати для побудови розумних міст, які покликані підвищити ефективність міської інфраструктури та покращити якість життя мешканців. Міста можуть оптимізувати роботу та надавати своїм жителям кращі послуги, встановлюючи датчики та інші пристрої IoT, які відстежують трафік, якість повітря та додаткові важливі показники. Ми можемо очікувати, що в 2023 році більше будівельних компаній запустять програми розумного міста, які використовують IoT для підвищення рівня життя мешканців."
      },
      {
        type: "h2",
        style: "",
        text: "5. Медичні прилади"
      },
      {
        type: "image",
        style: "",
        image: "https://res.cloudinary.com/dq9w8bxge/image/upload/v1686230852/big_2_1_xv5tfb.png"
      },
      {
        type: "p",
        style: "",
        text: "Сфера медичних пристроїв є ще однією сферою, де IoT очікує мати значний вплив. Пропонуючи моніторинг і аналіз життєво важливих показників та інших параметрів здоров’я в реальному часі, медичні пристрої з підтримкою IoT можуть покращити результати лікування пацієнтів. Ці інструменти можна використовувати для виявлення потенційних проблем зі здоров’ям до того, як вони переростуть у серйозні, що дозволяє медичним працівникам діяти швидко та надавати допомогу. Ймовірно, ми побачимо, що все більше медичних пристроїв включатимуть технологію IoT у міру її розвитку, що дасть пацієнтам і медичним працівникам доступ до більш детальної та точної інформації про здоров’я, що зрештою покращить результати лікування пацієнтів."
      },
      {
        type: "h2",
        style: "",
        text: "Підводячи підсумок, можна сказати, що Інтернет речей має потенціал змінити спосіб роботи компаній і зв’язок із клієнтами, постачальниками та партнерами. Основними інноваціями IoT, які трансформують бізнес у 2023 році, є автономні транспортні засоби, розумні заводи, персоналізований маркетинг і розумні міста. Ми можемо очікувати більшої ефективності, нижчих цін і більшої креативності в різних галузях, оскільки компанії продовжуватимуть застосовувати ці технології."
      },

    ]
  },

];

export const MainTitles_en = [
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "find",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "solution",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "create",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "design",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "develop",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "software",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
];

export const MainTitles_ua = [
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "знайдемо",
      type: "underlined",
    },
    {
      value: "найкраще",
      type: "black",
    },
    {
      value: "рішення",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "створимо",
      type: "underlined",
    },
    {
      value: "найкращий",
      type: "black",
    },
    {
      value: "дизайн",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "розробимо",
      type: "underlined",
    },
    {
      value: "найкращий",
      type: "black",
    },
    {
      value: "софт",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
];
