import {
  behanceBlackSVG, behanceSVG,
  downArrowBlackSVG, downArrowSVG,
  emailBlackSVG, emailSVG,
  facebookBlackSVG, facebookSVG,
  linkedInBlackSVG, rightArrowSVG,
  linkedInSVG,
  upworkBlackSVG, upworkSVG,
} from "../Constants/SVG";

import { MemoizedNavigation } from "./Navigation";
import { motion, AnimatePresence  } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import React, { useEffect, useState } from "react";
import { ReactComponent as LogoIcon } from "../Assets/Icons/logo.svg";
import { paths } from "../Constants";
import { MainTitles_en, MainTitles_ua } from "../mock-data/mock-data";

function HeaderSect({ arrowLink = null, bgClassName = null, title, mainBanner, page = 'main' }) {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { pathname } = useLocation();
  
  const [localization, setLocalization] = useState(null);
  
  useEffect(() => {
    changeLanguage(lang)
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }, [lang]);
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
    setLocalization(lng);
    activeLanguage(lng);
  };
  
  const activeLanguage = (lng) => {
    document.getElementsByClassName("active-language")[0]?.classList.remove("active-language");
    document.getElementById(lng)?.classList.add("active-language");
  }
  
  const isDomainsPage = page === 'domains';
  
  const [isVisibleBannerText, setIsVisibleBannerText] = useState(true);
  const [indexBannerText, setIndexBannerText] = useState(0);
  const mainTitles = localization === 'en' ? MainTitles_en : MainTitles_ua;
  
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisibleBannerText((prev) => !prev);
      
      if (!isVisibleBannerText) {
        setIndexBannerText((prevIndex) => (prevIndex + 1) % mainTitles.length);
      }
    }, isVisibleBannerText ? 5000 : 500);
    
    return () => clearInterval(interval);
  }, [isVisibleBannerText, mainTitles.length]);

  return (
    <div className={`main-header ${bgClassName ? `${bgClassName}-main-header` : ''}`}>
      <div className="main-header-container">
        <div className="main-header-menu">
          <Link to={`/${lang}`}>
            <LogoIcon />
          </Link>
          <MemoizedNavigation page={page}/>
        </div>
        <div className="main-header-sect-1">
          <div className="main-header-sect-1-content">
            <motion.p
              initial={{ x: -200, opacity: 0, scale: 0.5 }}
              whileInView={{ x: 0, opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className={`main-header-text ${isDomainsPage ? 'main-domains-text' : ''}`}
            >
              <Trans>Do you want to optimize your business?</Trans>
            </motion.p>
            
            {mainBanner
              ? (
                <div className={`main-title-wrapper ${lang === 'ua' ? 'main-title-wrapper-ua' : ''}`}>
                  <AnimatePresence>
                    {isVisibleBannerText && (
                      <motion.div
                        key={indexBannerText}
                        initial={{ x: -200, opacity: 0, scale: 0.5 }}
                        animate={{ x: 0, opacity: 1, scale: 1 }}
                        exit={{ x: -200, opacity: 0, scale: 0.5 }}
                        transition={{ duration: 0.5 }}
                        className="main-header-text main-title"
                      >
                        {mainTitles[indexBannerText].map((word, i) => (
                          <p key={word.value + i} className={`main-title-word main-title-word-${word.type}`}>
                            {word.value}
                          </p>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              )
              : (
                <motion.p
                  initial={{ x: -200, opacity: 0, scale: 0.5 }}
                  whileInView={{ x: 0, opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  className={`main-header-text main-title ${isDomainsPage ? 'main-domains-text' : ''}`}
                >
                  <Trans>{title}</Trans>
                </motion.p>
              )}
            <motion.div
              initial={{ x: -200, opacity: 0, scale: 0.5 }}
              whileInView={{ x: 0, opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="main-buttons"
            >
              <HashLink
                smooth
                to={`/${lang}${paths.contactUs}`}
                className="main-color-btn"
              >
                <Trans>Contact us</Trans>
              </HashLink >
              
              <Link to={`/${lang}${paths.portfolioPage}`} className="main-light-btn">
                <Trans>Case study</Trans> {rightArrowSVG}
              </Link>
            </motion.div>
          </div>
          
          {mainBanner && <div className="main-header-sect-1-image" />}
          
          {mainBanner && (
            <motion.div
              initial={{ x: -200, opacity: 0, scale: 0.5 }}
              whileInView={{ x: 0, opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="main-links"
            >
              <Link to="https://clutch.co/profile/tetalab-0" aria-label="clutch" target="_blank" className="main-clutch"/>
              
              <Link to="https://www.upwork.com/ag/tetalab/" aria-label="upwork" target="_blank" className="main-upwork"/>
            </motion.div>
          )}
        </div>
        <div className="main-header-sect-2">
          <div className="main-header-resources">
            <Link to="mailto:taras.teslyuk@tetalab.com" aria-label="email">
              {isDomainsPage ? emailSVG : emailBlackSVG}
            </Link>
            <Link to="https://www.linkedin.com/company/tetalab/" aria-label="linkedin" target="_blank">
              {isDomainsPage ? linkedInSVG : linkedInBlackSVG}
            </Link>
            <Link to="https://www.upwork.com/ag/tetalab/" aria-label="upwork" target="_blank">
              {isDomainsPage ? upworkSVG : upworkBlackSVG}
            </Link>
            <Link to="https://www.behance.net/tetalab" aria-label="behance" target="_blank">
              {isDomainsPage ? behanceSVG : behanceBlackSVG}
            </Link>
            <Link to="https://www.facebook.com/TetaLab-108468204017631/" aria-label="facebook" target="_blank">
              {isDomainsPage ? facebookSVG : facebookBlackSVG}
            </Link>
          </div>
          <div className="main-header-arrow">
            <HashLink smooth to={`/${localization}${arrowLink ?? ''}#domains`} aria-label="domains">
              {isDomainsPage ? downArrowSVG : downArrowBlackSVG}
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HeaderSect)