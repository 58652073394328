import React from 'react';
import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { Countries } from "../../Constants/Countries";
import { Counter } from "../../Components/Counter";

function MainAbout() {
  const { i18n } = useTranslation();
  
  return (
    <div className="sect" id="about">
      <div className="sect-container">
        <motion.div
          initial={{ x: 0, opacity: 0, scale: 1 }}
          whileInView={{ x: 0, opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="sect-title"
        >
          <Trans>About</Trans>
        </motion.div>
        <motion.div
          initial={{ x: -300, opacity: 0, scale: 1 }}
          whileInView={{ x: 0, opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="sect-article"
        >
          <div className="article-info">
            <p>{i18n.t("Tetalab was founded in Lviv at the west of Ukraine. We are a team of talented developers, designers, testers, and managers. We believe that we can grow as a team by working together and making our customers happy. We are constantly looking for new challenges and possibilities. We can help to design and develop your idea into a working solution.")}</p>
            <p>
              <Trans>We already successfully completed projects for customers from different countries:</Trans>
            </p>
            <p>
              <Trans>— USA, Germany, Austria, France, Belgium, Sweden, Romania, Ukraine, Israel, India, and
                Australia.</Trans>
            </p>
          </div>
          <LazyLoad>
            <img
              className="mobile-map"
              alt="map"
              src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1678870736/Main/Map_mobile_h21lgk.png"
            />
          </LazyLoad>
          <LazyLoad>
            <div className="map">
              <img
                className="map-img"
                alt="map"
                src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1678870736/Main/Map_ydexfk.png"
              />
              {Countries.map((country, i) =>
                <div className="map-dot" id={`${country}-${i18n.language}`} key={"country" + i}>
                  <div className="map-dot-bg"/>
                </div>
              )}
            </div>
          </LazyLoad>
        </motion.div>
        <Counter/>
      </div>
    </div>
  );
}

export default MainAbout;