import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';

i18n
    .use(initReactI18next)
    .use(detector)
    .use(HttpApi)
    .init({
        fallbackLng: "en",
        lng: localStorage.getItem("lang") || "ua",
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        }
    });

export default i18n;
