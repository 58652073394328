import "./index.css";
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import { Helmet } from "react-helmet";
import Preloader from "./Components/Preloader";
import { useTranslation } from "react-i18next";
import { RoutesComponent } from "./Constants/Routes";


const Footer = lazy(() => import('./Layouts/Footer/Footer'));

function App() {
  const { i18n } = useTranslation();
  
  const [isLoading, setIsLoading] = useState(true);
  
  const userLocale = navigator.language.substring(0, 2);
  let queryLang = window.location.pathname.split("/")[1];
  
  useEffect(() => {
    queryLang = window.location.pathname.split("/")[1];
    i18n.changeLanguage(queryLang);
    localStorage.setItem("lang", queryLang);
  }, [window.location.search]);
  
  useLayoutEffect(() => {
    if (document.readyState !== "loading") {
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }, []);
  
  useEffect(() => {
    const script = document.createElement('script');
    
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;
    
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  return (
    <div>
      {!isLoading &&
        <Helmet>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/7329964.js"/>
        </Helmet>
      }
      <BrowserRouter>
        <Suspense fallback={<Preloader/>}>
          <ScrollToTop>
            <RoutesComponent isLoading={isLoading} queryLang={queryLang} userLocale={userLocale}/>
            {/*<div className="widget-box">*/}
            {/*  <div className="widget-container">*/}
            {/*    <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="8"*/}
            {/*         data-height="300" data-nofollow="true" data-expandifr="true" data-reviews="2149820,2137576,2129195"*/}
            {/*         data-clutchcompany-id="1835287"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {!isLoading && <Footer/>}
          </ScrollToTop>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default React.memo(App)