export const TEAM = {
  UIUX_DESIGNER: {
    title: "UI/UX Designer",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709128973/tetalab%20site/uiux_mszbpb.png"
  },
  MOBILE_DEVELOPER: {
    title: "Mobile Developer",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709128973/tetalab%20site/mobile_iskb0x.png"
  },
  FRONTEND_DEVELOPER: {
    title: "FrontEnd Developer",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709128973/tetalab%20site/frontend_ba4onr.png"
  },
  BACKEND_DEVELOPER: {
    title: "BackEnd Developer",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709128972/tetalab%20site/backend_bpu5hh.png"
  },  
  DESKTOP_DEVELOPER: {
    title: "Desktop Developer",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789544/desktop_developer_gfyoh1.svg"
  },
  ELECTRONIC_ENGINEER: {
    title: "Electronic Engineer",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789544/electronic_engineer_sowyi0.svg"
  },
  QA: {
    title: "QA",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709128972/tetalab%20site/qa_oefcrx.png"
  }
}

export const TERM = {
  THREE_WEEKS: {
    title: "3 weeks",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720788020/3_weeks_hhmuof.svg",
  },
  FOUR_WEEKS: {
    title: "4 weeks",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720788020/4_weeks_mxbr44.svg",
  },
  SIX_WEEKS: {
    title: "6 weeks",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720788020/6_weeks_mme1uj.svg",
  },
  NINE_WEEKS: {
    title: "9 weeks",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720788020/9_weeks_dp9674.svg",
  },
}

export const PLATFORMS = {
  IOS: {
    title: "iOS",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535426/Projects/Frame_24_lhzdvu.svg"
  },
  ANDROID: {
    title: "Android",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/Frame_23_gfzbav.svg"
  },
  WEB: {
    title: "Web",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789043/icon_web_pczzu9.svg"
  }
}

export const TECHNOLOGIES = {
  KOTLIN: {
    title: "Kotlin",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535429/Projects/Kotlin_htxixb.svg"
  },
  SWIFT: {
    title: "Swift",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789043/icon_swift_l5q7wq.svg"
  },
  ANDROID: {
    title: "Android",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535424/Projects/Android_a7oauw.svg"
  },
  TEXT_TO_SPEECH: {
    title: "Text To Speech",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535422/Projects/Text_To_Speech_kvznhn.svg"
  },
  IN_APP_PURCHASE: {
    title: "In-App Purchase",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535427/Projects/In-App_Purchase_ystddx.svg"
  },
  ROOM: {
    title: "Room",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535422/Projects/Room_ag0cxp.svg"
  },
  C: {
    title: "C",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/C_hftpwp.svg"
  },
  PYTHON: {
    title: "Python",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535422/Projects/Python_ql3lmu.svg"
  },
  PCB: {
    title: "PCB",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310185/tetalab%20site/PCB_mj0q6g.svg"
  },
  KEYBOARD: {
    title: "Keyboard",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549431/keyboard_bcizcl.jpg"
  },
  BLUETOOTH: {
    title: "Bluetooth",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789043/icon_bluetooth_kigzrr.svg"
  },
  WIFI: {
    title: "WiFi",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550560/wifi_kohhst.jpg"
  },
  NODE_MCU: {
    title: "Node MCU",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550560/nodemcu_gexzej.jpg"
  },
  FIREBASE: {
    title: "Firebase",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550560/firebase_wnhye5.jpg"
  },
  OSM: {
    title: "OSM",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552482/OSM_li4jic.jpg"
  },
  MEDIA_PLAYER: {
    title: "Media Player",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553686/MEDIA_PLAYER_whxp7h.jpg"
  },
  JAVA: {
    title: "Java",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709554795/JAVA_odpne1.jpg"
  },
  REACT: {
    title: "React",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716980214/React_wcyvso.svg",
  },
  NODEJS: {
    title: "Node.js",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1720789043/icon_node_gruou2.svg",
  }
}

export const TOOLS = {
  FIGMA: {
    title: "Figma",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/Figma_cyawqc.svg"
  },
  INDESIGN: {
    title: "InDesign",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535428/Projects/InDesign_so7tcp.svg"
  },
  ILLUSTRATOR: {
    title: "Adobe Illustrator",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535424/Projects/AI_sge4b2.svg"
  },
  SKETCH: {
    title: "Sketch",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556548/SKETCH_wknflw.jpg"
  },
  PHOTOSHOP: {
    title: "Adobe Photoshop",
    img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716980387/Photoshop_bcx02s.svg"
  }
}
