import "swiper/swiper-bundle.css";
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Link, useParams } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import "../Assets/Styles/Blog.css";
import { useBreakpoints } from "../utils/useBreakpoints";
import { format } from "date-fns";

const createBlogItems = (blogArray, bg, lang) => {
  return blogArray.map((item, i) => (
    <SwiperSlide key={`blog-${i}`}>
      <Link
        className="carousel-center"
        to={`/${lang}/blog/${item.name}`}
        state={{ item }}
      >
        <div className="article-card bg-white" key={item.id}>
          <img
            className="article-card-img"
            alt="blog"
            src={item.img}/>
          <div className="article-card-text">
            <p className="article-card-heading">{item.title}</p>
            <div className="article-card-time">
              <p className="article-card-date">{format(item?.date, 'MMM dd, yyyy')}</p>
              <p>{item.readTime} min read</p>
            </div>
          </div>
        </div>
      </Link>
    </SwiperSlide>
  ))
    ;
};

const createPortfolioItems = (portfolioArray, bg, lang) => {
  return portfolioArray.map((item, i) => (
    <SwiperSlide key={`portfolio-${i}`}>
      <Link
        to={`/${lang}/portfolio/${item.id}`}
        state={{ item }}
        className="sect-cards-card service-cards-item portfolio-card-wrapper portfolio-card-swiper"
      >
        <LazyLoad className="portfolio-img">
          <img
            alt="ui/ux"
            className="portfolio-img"
            src={item.img}
          />
        </LazyLoad>
        <p className="project-mini-card-title">{item.title}</p>
      </Link>
    </SwiperSlide>
  ));
};


export function Carousel({ arr, bg, isArticle, isPortfolio }) {
  const { lang } = useParams();
  const { isMdScreen, isXlScreen } = useBreakpoints();
  const [items, setItems] = useState([]);
  
  const isControlButtonHidden = !isMdScreen || items.length <= 2 || isXlScreen && items.length === 3;
  
  useEffect(() => {
    if (isArticle) {
      setItems(createBlogItems(arr, bg, lang));
    } else {
      setItems(createPortfolioItems(arr, bg, lang));
    }
  }, [arr, bg, lang, isArticle]);
  
  return (
    <div className="carousel-wrap">
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{ prevEl: '.carousel-swiper-button-prev', nextEl: '.carousel-swiper-button-next' }}
        pagination={!isMdScreen ? { clickable: true } : false}
        spaceBetween={50}
        slidesPerView={1}
        breakpoints={{
          768: { slidesPerView: 2 },
          1280: { slidesPerView: 3 }
        }}
        loop={true}
      >
        {items}
      </Swiper>
      
      <div
        className={`carousel-swiper-button-prev swiper-button-prev ${isControlButtonHidden ? 'swiper-button-prev-hidden' : ''}`}/>
      <div
        className={`carousel-swiper-button-next swiper-button-next ${isControlButtonHidden ? 'swiper-button-next-hidden' : ''}`}/>
    </div>
  );
}

export const MemoizedCarousel = React.memo(Carousel);
