import "./Constants/i18n";
import "./index.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-6V1FWWM5DL")
const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <App/>
);