import React from 'react';
import {motion} from "framer-motion";
import {Trans} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { paths } from "../../Constants";

function MainDomains() {
  const {lang} = useParams();

  return (
    <div className="sect" id="domains">
      <div className="sect-container">
        <motion.div
          initial={{x: 0, opacity: 0, scale: 1}}
          whileInView={{x: 0, opacity: 1, scale: 1}}
          viewport={{once: true}}
          transition={{duration: 0.5}}
          className="sect-title"
        >
          <Trans>Domains</Trans>
        </motion.div>
        <div className="sect-cards">
          <Link to={`/${lang}${paths.domains}/tourism`}>
            <div id="tourism_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="tourism"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535376/Main/Tourism_a78j7x.svg"
                />
              </LazyLoad>
              <p><Trans>Tourism</Trans></p>
            </div>
          </Link>
          <Link to={`/${lang}${paths.domains}/entertainment`}>
            <div id="entertainment_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="entertainment"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535379/Main/Entertainment_dk3rwi.svg"
                />
              </LazyLoad>
              <p><Trans>Entertainment</Trans></p>
            </div>
          </Link>
          <Link to={`/${lang}${paths.domains}/iot`}>
            <div id="IoT_Hardware_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="iot"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/IoT_Hardware_nmypj4.svg"
                />
              </LazyLoad>
              <p><Trans>IoT/Embedded System</Trans></p>
            </div>
          </Link>
          <Link to={`/${lang}${paths.domains}/ordering-system`}>
            <div id="ordering_systems_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="ordering"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535376/Main/Ordering_Systems_on4wbd.svg"
                />
              </LazyLoad>
              <p><Trans>Ordering Systems</Trans></p>
            </div>
          </Link>
          <Link to={`/${lang}${paths.domains}/tools-&-utilities`}>
            <div id="tools_and_utilities_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="tools"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535376/Main/Tools_and_utilities_z2jej9.svg"
                />
              </LazyLoad>
              <p><Trans>Tools and Utilities</Trans></p>
            </div>
          </Link>
          <Link to={`/${lang}${paths.domains}/education`}>
            <div id="tools_and_utilities_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="tools"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535378/Main/Education_q9t8xn.svg"
                />
              </LazyLoad>
              <p><Trans>Education</Trans></p>
            </div>
          </Link>
          {/*<Link to={`/${lang}${paths.domains}/healthcare`}>*/} {/*TODO temporary commented Healthcare*/}
          {/*  <div id="tools_and_utilities_card" className="sect-cards-card">*/}
          {/*    <LazyLoad>*/}
          {/*      <img alt="medical"*/}
          {/*           src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718088473/Main/Medical_o3ccy3.svg"*/}
          {/*      />*/}
          {/*    </LazyLoad>*/}
          {/*    <p><Trans>Medical</Trans></p>*/}
          {/*  </div>*/}
          {/*</Link>*/}
          <Link to={`/${lang}${paths.domains}/ai`}>
            <div id="tools_and_utilities_card" className="sect-cards-card">
              <LazyLoad>
                <img alt="medical"
                     src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718371485/Main/Frame_wxgsv3.svg"
                />
              </LazyLoad>
              <p><Trans>AI</Trans></p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainDomains;