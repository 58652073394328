import React from "react";
import Preloader from "../../Components/Preloader";
import MainDomains from "./MainDomains";
import MainServices from "./MainServices";
import MainAbout from "./MainAbout";
import MainCareers from "./MainCareers";
import MainPortfolio from "./MainPortfolio";
import MainFeedback from "./MainFeedback";
import MainContactUs from "./MainContactUs";
import HeaderSect from "../../Components/HeaderSect";

function Main({ isLoading }) {
  return (
    <>
      {isLoading ? <Preloader/> :
        <div className="main">
          <HeaderSect title={`You need<br/>new software tools`} mainBanner />
          <MainDomains/>
          <MainServices/>
          <MainAbout/>
          <MainPortfolio/>
          <MainCareers/>
          <MainFeedback/>
          <MainContactUs/>
        </div>}
    </>
  );
}

export default React.memo(Main)
