import "../Assets/Styles/Preloader.css"
import React from "react";

export default function Preloader() {
    return (
        <div className="preloader">
            <img alt="bg-img" className="hidden" src="https://res.cloudinary.com/dvjdkgzbe/image/upload/v1678214793/Main/main-bg_yzpjy3.jpg"/>
            <div className="sk-spinner sk-spinner-rotating-plane"/>
        </div>
    );
}