import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import LanguageChanger from "./LanguageChanger";

import { dropdownSVG } from "../Constants/SVG";
import { paths } from "../Constants/index";
import { useBreakpoints } from "../utils/useBreakpoints";
import { AnimatePresence, motion } from "framer-motion";

const MobileNav = ({ i18n, page }) => {
  const { lang } = useParams();
  const dropdownRef = useRef();

  const [isOpenDropdowns, setIsOpenDropdowns] = useState({
    menu: false,
    service: false,
    domains: false,
    lang: false
  })

  const isDomainsPage = page === 'domains';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropdowns({
          menu: false,
          service: false,
          domains: false,
          lang: false
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleMenuDropdown = (event, isOutClick = null) => {
    setIsOpenDropdowns({
      menu: !isOpenDropdowns.menu,
      service: false,
      domains: false,
      lang: false
    });
  };

  const toggleServicesDropdown = () => {
    setIsOpenDropdowns({
      ...isOpenDropdowns,
      service: !isOpenDropdowns.service,
      domains: false,
      lang: false
    })
  };

  const toggleDomainsDropdown = () => {
    setIsOpenDropdowns({
      ...isOpenDropdowns,
      service: false,
      domains: !isOpenDropdowns.domains,
      lang: false
    })
  };

  return (
    <div ref={dropdownRef} className="language-changer header-dropdown-container">
      <div className={`current-language ${isDomainsPage ? 'domains-burger' : ''}`} onClick={toggleMenuDropdown}>
        {dropdownSVG}
      </div>
      <AnimatePresence>
        {isOpenDropdowns.menu && (
          <motion.div
            initial={{ x: 0, opacity: 0, scale: 1 }}
            whileInView={{ x: 0, opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="language-dropdown header-dropdown">
              <div className="header-dropdown-item">
                <div className="language-changer header-dropdown-container">
                  <div className="current-language" onClick={toggleDomainsDropdown}>
                    <Trans>Domains</Trans>
                  </div>
                  <AnimatePresence>
                    {isOpenDropdowns.domains && (
                      <motion.div
                        initial={{ x: 0, opacity: 0, scale: 1 }}
                        whileInView={{ x: 0, opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="language-dropdown header-dropdown-domains">
                          <Link to={`/${lang}${paths.domains}/iot`}>{i18n.t('IoT')}</Link>
                          {/*<Link to={`/${lang}${paths.domains}/healthcare`}>{i18n.t('Healthcare')}</Link>*/} {/*TODO temporary commented Healthcare*/}
                          <Link to={`/${lang}${paths.domains}/tourism`}>{i18n.t('Tourism')}</Link>
                          <Link to={`/${lang}${paths.domains}/ordering-system`}>{i18n.t('Ordering Systems')}</Link>
                          <Link to={`/${lang}${paths.domains}/education`}>{i18n.t('Education')}</Link>
                          <Link to={`/${lang}${paths.domains}/tools-&-utilities`}>{i18n.t('Tools & Utilities')}</Link>
                          <Link to={`/${lang}${paths.domains}/entertainment`}>{i18n.t('Entertainment')}</Link>
                          <Link to={`/${lang}${paths.domains}/ai`}>{i18n.t('AI')}</Link>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              <div className="header-dropdown-item">
                <div className="language-changer header-dropdown-container">
                  <div className="current-language" onClick={toggleServicesDropdown}>
                    <Trans>Services</Trans>
                  </div>
                  <AnimatePresence>
                    {isOpenDropdowns.service && (
                      <motion.div
                        initial={{ x: 0, opacity: 0, scale: 1 }}
                        whileInView={{ x: 0, opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="language-dropdown header-dropdown-domains">
                          <Link to={`/${lang}${paths.services}/android`}>Android</Link>
                          <Link to={`/${lang}${paths.services}/ios`}>IOS</Link>
                          <Link to={`/${lang}${paths.services}/react`}>React</Link>
                          <Link to={`/${lang}${paths.services}/node`}>Node</Link>
                          <Link to={`/${lang}${paths.services}/flutter`}>Flutter</Link>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              <Link to={`/${lang}${paths.portfolioPage}`}>{i18n.t('Portfolio')}</Link>
              <Link to={`/${lang}${paths.careersPage}`}>{i18n.t('Careers')}</Link>
              <Link to={`/${lang}${paths.forStartups}`}>{i18n.t('For Startups')}</Link>
              <Link to={`/${lang}${paths.blog}`}>{i18n.t('Blog')}</Link>
              <Link to={`/${lang}${paths.contactUs}`}>{i18n.t('Contact us')}</Link>
              <div className="header-dropdown-lang">
                <LanguageChanger isDomainsPage={isDomainsPage}
                                 isLangDropdownOpen={isOpenDropdowns}
                                 setIsLangDropdownOpen={setIsOpenDropdowns}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export function Navigation({ page }) {
  const { isXlCustomScreen } = useBreakpoints();
  const url = window.location.toString();
  const query = url.split("/");
  
  const [activeLink, setActiveLink] = useState(`/${query[query.length - 1]}`);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [isServicesSelectOpen, setServicesSelectOpen] = useState(false);
  
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  
  useEffect(() => {
    setActiveLink(`/${query[query.length - 1]}`);
  }, [query])
  
  const links = [
    {
      link: paths.portfolioPage,
      text: i18n.t('Portfolio'),
      type: 'link'
    },
    {
      link: paths.careersPage,
      text: i18n.t('Careers'),
      type: 'link'
    },
    {
      link: paths.forStartups,
      text: i18n.t('For Startups'),
      type: 'link'
    },
    {
      link: paths.blog,
      text: i18n.t('Blog'),
      type: 'link'
    },
    {
      link: paths.contactUs,
      text: i18n.t('Contact us'),
      type: 'hash'
    }
  ]

  const isDomainsPage = page === 'domains';

  return (
    <>
      <nav className={isDomainsPage ? "main-header-nav header-nav" : "main-header-nav"} style={{ gap: "30px" }}>
        <div className='domains-parent'
             onClick={() => {
               setSelectOpen(!isSelectOpen)
               setServicesSelectOpen(false)
             }}
        >
          <div className={url.includes(paths.domains) ? 'active-a' : 'link'}>{i18n.t('Domains')}</div>
          <AnimatePresence>
            {isSelectOpen && (
              <motion.div
                initial={{ x: 0, opacity: 0, scale: 1 }}
                whileInView={{ x: 0, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="select">
                  <div onClick={() => navigate(`/${lang}${paths.domains}/iot`)} className="option">
                    <Trans>IoT</Trans>
                  </div>
                  {/*<div onClick={() => navigate(`/${lang}${paths.domains}/healthcare`)} className="option">*/} {/*TODO temporary commented Healthcare*/}
                  {/*  <Trans>Healthcare</Trans></div>*/}
                  <div onClick={() => navigate(`/${lang}${paths.domains}/tourism`)} className="option">
                    <Trans>Tourism</Trans>
                  </div>
                  <div onClick={() => navigate(`/${lang}${paths.domains}/ordering-system`)} className="option">
                    <Trans>Ordering Systems</Trans>
                  </div>
                  <div onClick={() => navigate(`/${lang}${paths.domains}/education`)} className="option">
                    <Trans>Education</Trans></div>
                  <div onClick={() => navigate(`/${lang}${paths.domains}/tools-&-utilities`)} className="option">
                    <Trans>Tools & Utilities</Trans>
                  </div>
                  <div onClick={() => navigate(`/${lang}${paths.domains}/entertainment`)} className="option">
                    <Trans>Entertainment</Trans>
                  </div>
                  <div onClick={() => navigate(`/${lang}${paths.domains}/ai`)} className="option">
                    <Trans>AI</Trans>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="domains-parent"
             onClick={() => {
               setSelectOpen(false)
               setServicesSelectOpen(!isServicesSelectOpen)
             }}
        >
          <div className={url.includes(paths.services) ? 'active-a' : 'link'}>{i18n.t('Services')}</div>
          <AnimatePresence>
            {isServicesSelectOpen && (
              <motion.div
                initial={{ x: 0, opacity: 0, scale: 1 }}
                whileInView={{ x: 0, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              ><div className='select'>
                <div onClick={() => navigate(`/${lang}${paths.services}/android`)} className="option">Android</div>
                <div onClick={() => navigate(`/${lang}${paths.services}/ios`)} className="option">IOS</div>
                <div onClick={() => navigate(`/${lang}${paths.services}/react`)} className="option">React</div>
                <div onClick={() => navigate(`/${lang}${paths.services}/node`)} className="option">Node</div>
                <div onClick={() => navigate(`/${lang}${paths.services}/flutter`)} className="option">Flutter</div>
              </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        
        {links.map(({ type, link, text }, index) => (
          <>
            {type === 'hash' ? (
              <HashLink
                smooth
                to={`/${lang}${link}`}
                key={index}
              >
                {text}
              </HashLink>
            ) : (
              <Link
                to={`/${lang}${link}`}
                className={`${activeLink === link ? 'active-a' : 'link'}`}
                key={index}
              >
                {text}
              </Link>
            )}
            
            {index === links.length - 2 ? (
              <LanguageChanger isDomainsPage={isDomainsPage}/>
            ) : null}
          </>
        ))}
      </nav>
      
      {!isXlCustomScreen && <MobileNav i18n={i18n} page={page}/>}
    </>
  )
}

export const MemoizedNavigation = React.memo(Navigation)